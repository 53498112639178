@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

@font-face {
  font-family: "Mali-Bold";
  src: local("Mali-Bold"), url("./fonts/Mali-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: "Mali-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.main {
  height: 100vh;
}

.mint-page-wrapper {
  display: flex;
  height: 100%;
}

.mint-page-left {
  flex: 0 0 60%;
  max-width: 60%;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.title {
  font-size: 40px;
}

.desc {
  font-size: 25px;
}

.mint-page-left .bears {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bears .bear {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bear img {
  width: 100%;
  display: block;
}

.mint-page-right {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.mint-btn {
  padding: 24px;
}

@media only screen and (max-width: 1000px) {
  .mint-page-wrapper {
    flex-direction: column;
  }
  .mint-page-left {
    max-width: none;
  }
  .bear img {
    width: 70%;
  }
}

@media only screen and (max-width: 750px) {
  .title {
    font-size: 25px;
  }
  .desc {
    font-size: 17px;
  }
  .mint-btn {
    padding: 15px;
    font-size: 14px;
  }
  .mint-btn * {
    font-size: 13px !important;
  }
}
